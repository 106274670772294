import * as VueRouter from 'vue-router'
import MainPage from '../pages/MainPage'
import AboutPage from '../pages/AboutPage'
import ContactsPage from '../pages/ContactsPage'
import ProjectPage from '../pages/ProjectPage'
import ProjectsListPage from '../pages/ProjectsListPage'
import LoginPage from '../pages/LoginPage'
import MyLayout from '../pages/MyLayout'
import AdminCabinet from '../pages/admin/AdminCabinet'
import AdminMarks from '../pages/admin/AdminMarks'
import EditProfile from '../pages/admin/EditProfile'
import store from '@/store'
export default VueRouter.createRouter({
    history:VueRouter.createWebHashHistory(),
    routes:[
        {
            path:'/',
            name:'myLayout',
            component:MyLayout,
            children:[
                {
                    path:'',
                    name:'mainPage',
                    component:MainPage
                },
                {
                    path:'/about',
                    name:'aboutPage',
                    component:AboutPage
                },
                {
                    path:'/project/:id',
                    name:'projectPage',
                    component:ProjectPage
                },
                {
                    path:'/contacts',
                    name:'contactsPage',
                    component:ContactsPage
                },
                {
                    path:'/projectsList',
                    name:'projectsListPage',
                    component:ProjectsListPage
                },
            ]
        },
        {
            path:'/login',
            name:'loginPage',
            component:LoginPage
        }
        ,
        {
            path:'/admin',
            name:'adminLayout',
            component:MyLayout,
            beforeEnter:(to,from,next)=>{
                if (!store.getters.getLogged){
                    next({name:'loginPage'})
                }
                else{
                    next()
                }                
            },
            children:[
                {
                    path:'',
                    name:'cabinet',
                    component:AdminCabinet
                },  
                {
                    path:'editProfile',
                    name:'editProfile',
                    component:EditProfile
                },  
                {
                    path:'adminMarks',
                    name:'adminMarks',
                    component:AdminMarks
                },             
            ]
        }
    ]
})