<template>
    <div class="jumbotron my-5">
        <div class="container d-flex flex-column gap-5">
            <h1 class="display-3">Акселератор БайкалБиоФарм</h1>
            <p>
                <strong>Акселерационная программа поддержки проектных команд и студенческих инициатив для формирования
                    инновационных продуктов в рамках реализации федерального проекта «Платформа университетского
                    технологического предпринимательства» </strong>
                государственной программы Российской Федерации «Научно-технологическое развитие Российской Федерации» на
                <strong>базе</strong> Федерального государственного бюджетного организации высшего образования «Бурятский
                государственный
                университет имени Доржи Банзарова».
            </p>
            <h2>Краткое описание целей акселерационной программы</h2>
            <p>Программа поддержки проектных команд и студенческих инициатив для формирования инновационных продуктов
                федерального государственного образовательного учреждения высшего образования «Бурятский государственный
                университет им. Доржи Банзарова» представлена в составе заявки на участие в отборе образовательных
                организаций высшего образования в рамках реализации Акселерационных программ проекта «Платформа
                университетского технологического предпринимательства» государственной программы Российской Федерации
                «Научно-технологическое развитие Российской Федерации».
            </p>
            <p>Акселерационная программа направлена на содействие увеличению вклада федерального государственного
                образовательного учреждения высшего образования «Бурятский государственный университет им. Доржи Банзарова»в
                достижение национальных целей развития Российской Федерации на период до 2035 года, сбалансированное
                пространственное развитие страны, обеспечение доступности качественного высшего образования в субъектах
                Российской Федерации, развитие стартап-проектов проектных команд и студенческих инициатив в целях
                формирования инновационных продуктов и вовлечения проектных программ в технологическое предпринимательство
                по одному/или нескольким из технологических направлений, определенных с учетом приоритетных направлений
                развития науки, технологий и техники, развития компетенций этих проектных команд, доработки технологического
                решения и бизнес-модели, поиска и привлечения инвесторов и новых потребителей, масштабирования бизнеса,
                увеличения прибыли и (или) выручки в рамках реализации проекта «Платформа университетского технологического
                предпринимательства» государственной программы Российской Федерации «Научно-технологическое развитие
                Российской Федерации».</p>
            <p><strong>Акселерационная программа</strong> ФГБОУ ВО«Бурятский государственный университет им. Доржи
                Банзарова» преимущественно направлена на следующие тематические специализации:
            </p>
            <p><strong>Хелснет.</strong> На основе опыта проектной деятельности научных коллективов БГУ в рамках рынка Хэлснет возможна
            реализация проектов по разработке и внедрению новых лекарственных форм на основе растительного сырья, развитию
            инфраструктуры здравостроительства на основе методик восточной медицины и экотуризма;</p>

            <p><strong>Технет.</strong> На основе опыта проектной деятельности научных коллективов БГУ в рамках рынка Технет возможна реализация
            проектов по моделированию и синтезу новых материалов, разработке устройств и линий автоматизации
            производственных процессов, созданию инструментов и технологий в области материаловедения.</p>

            <p><strong>Эдунет.</strong> На основе опыта проектной деятельности научных коллективов БГУ в рамках рынка EduNet возможна реализация
            проектов по cозданию программных продуктов для обеспечения образовательного процесса при реализации сетевых форм
            обучения, созданию цифровых обучающих материалов и учебно-методических инструментов; развитию системы
            менеджмента образовательной среды.</p>

            <p><strong>Фуднет.</strong> Направленность на рынок сформирована по результатам акселератора, реализованного в 2022 году, где были
            созданы более 5стартап-проектов по направлениям рынка.</p>
        </div>
    </div>
</template>
<script>

export default {
    name: 'AboutPage',
    components: {

    }
}
</script>