<template>
    <div class="jumbotron my-5">
        <div class="container d-flex flex-column gap-5">
            <h1 class="display-3">Контакты</h1>
            <p><strong>Команда:</strong> &laquo;ООО Организаторы трека&raquo;<br>
            <strong>Вёрстка и дизайн:</strong> Думнов Владислав Александрович<br>
            <strong>Программирование сайта:</strong> Хабитуев Баир Викторович<br>
            <strong>Бэкэнд:</strong> Дерюгин Даниил Федорович</p>
        </div>
    </div>
</template>
<script>

export default {
    name: 'ContactsPage',    
}
</script>