<template>
    <ProjectList :fullList="true"/>
</template>

<script>
import ProjectList from '@/components/ProjectList.vue';

export default {
    name: 'ProjectsListPage',
    components: {    
    ProjectList
}
}
</script>